import { useNavigate } from "@remix-run/react";
import { ButtonGroup, Button } from "@chakra-ui/react";
import { GoogleIcon } from "~/components/ProviderIcons";
import { TypedSupabaseClient } from "~/root";
import { useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";

export const GoogleSigninButton = ({ label, redirectUrl, supabase }: { label: string; redirectUrl: string; supabase: TypedSupabaseClient }) => {
  const navigate = useNavigate();

  const [currentUrl, setCurrentUrl] = useState<string | null>(null);

  const handleGoogleSignin = async () => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: currentUrl || window.location.href,
      },
    });

    if (error) {
      console.error({ error });
    } else {
      const session = await supabase.auth.getSession();
      if (session.data.session?.user?.id) {
        mixpanel.identify(session.data.session.user.id);
      }
      mixpanel.track("Logged In", { Provider: "Google" });
      navigate(redirectUrl);
    }
  };

  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  return (
    <ButtonGroup variant="outline" spacing="4" colorScheme="gray" w="full">
      <Button flexGrow={1} leftIcon={<GoogleIcon />} onClick={handleGoogleSignin} w="full" py={3} h="auto" gap="1">
        {label}
      </Button>
    </ButtonGroup>
  );
};
