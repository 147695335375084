import { Text, Divider, HStack } from "@chakra-ui/react";

export function AuthContinueDivider({ text }: { text: string }) {
  return (
    <HStack mt={4} mb={6} spacing={4}>
      <Divider />
      <Text fontSize="md" whiteSpace="nowrap" color="gray.500">
        {text}
      </Text>
      <Divider />
    </HStack>
  );
}
